import ProjectServices from "../services/ProjectServices";

export const getProjects = () => async (dispatch) => {
  dispatch({
    type: "PROJECTS_LOAD_START",
  });

  try {
    const response = await ProjectServices.getAll();
    const responseData = response.data;
    console.log(responseData);
    let data = [];
    if (responseData.message == "success") data = responseData.body;
    dispatch({
      type: "PROJECTS_LOAD_SUCCESS",
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "PROJECTS_LOAD_ERROR",
      payload: err.message,
    });
  }
};

import React from "react";
import { Stack, Typography, Box } from "@mui/material";

const Description = () => {
  return (
    <Stack spacing={4} mb={6}>
      <Box justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          About Us
        </Typography>
      </Box>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
      The shape of the next era
CrowdEnergy is a startup with a mission to empower people to take control of their energy future. We believe everyone should have the right to make decisions about their energy consumption, and we're taking steps towards making this a reality.
Funding made easy
It's simple and easy for anyone to find and fund an energy project on CrowdEnergy. With just one click, you can find any energy project that's been shared with us by an organization or entrepreneur who wants money from passionate backers like you.
A better future starts here
Don't let your voice go unheard! Join our growing community of backers - from individuals who believe in renewable energy, to organizations who want more power over their own electricity consumption - and help us change the world.
      </Typography>
    </Stack>
  );
};

export default Description;

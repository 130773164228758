import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  CircularProgress,
  Paper,
  InputBase,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Button from "../../../components/DefaultButton";
import { connect } from "react-redux";
import { getProjects } from "../../../actions/projects";
import SearchIcon from "@mui/icons-material/Search";
import { sortTypes, categoryTypes, delay } from "../../../constants";
import ProjectCard from "../../../components/ProjectCard";

const Projects = ({ projects, getProjects }) => {
  const [items, setItems] = useState([]);
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [sortValues, setSortValues] = useState({
    category: "ALL",
    searchby: "projects",
    search: "",
    sort: "OLDER",
  });

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    setItems(projects.data);
    setItemsFiltered(projects.data);
  }, [projects]);

  const sortItems = (event) => {
    setSortValues((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const handleFilter = async () => {
    var tempItems = [...items];
    setLoading(true);
    if (sortValues.search != "") {
      tempItems = tempItems.filter((item) => {
        return item.project_title
          .toLowerCase()
          .includes(sortValues.search.toLowerCase());
      });
    }
    await delay(200);
    setItemsFiltered(tempItems);
    setLoading(false);
  };

  const handleSort = async (event) => {
    sortItems(event);
    var value = event.target.value;
    var tempItems = [...itemsFiltered];
    setLoading(true);
    if (value == "NEWEST") {
      tempItems.sort((a, b) => {
        return b.project_id - a.project_id;
      });
    }
    if (value == "OLDER") {
      tempItems.sort((a, b) => {
        return a.project_id - b.project_id;
      });
    }
    await delay(200);
    setItemsFiltered(tempItems);
    setLoading(false);
  };

  return (
    <div>
      <Box mb={3} justifyContent="center">
        <Typography
          variant="h4"
          color="textPrimary"
          textAlign="center"
          gutterBottom
        >
          Projects
        </Typography>
        <Typography
          variant="h6"
          color="textPrimary"
          textAlign="justify"
          gutterBottom
        >
          - It takes only 2 minutes! All you need to do is choose your project
          and click "contribute".
        </Typography>
        <Typography
          variant="h6"
          color="textPrimary"
          textAlign="justify"
          gutterBottom
        >
          - Investments are always backed by the project you back, meaning that
          if they can't meet their goal, your contribution will be refunded.
          Start small and go big!
        </Typography>
        <Typography
          variant="h6"
          color="textPrimary"
          textAlign="justify"
          sx={{ pb: 4 }}
        >
          - Diversity of Projects We have projects ranging from 25kW to 500kW,
          in various industry verticals to pick & invest in your budget. Choose
          the one that's best for you and start funding!
        </Typography>
      </Box>
      <Grid container mb={3} spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Paper
            component="form"
            sx={{
              p: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              variant="standard"
              name="category"
              select
              InputProps={{
                disableUnderline: true,
              }}
              sx={{ ml: 1, flex: 0.3 }}
              value={sortValues.category || ""}
              onChange={sortItems}
            >
              {categoryTypes.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <InputBase
              name="search"
              sx={{ ml: 1, flex: 1 }}
              placeholder="Find Projects"
              value={sortValues.search || ""}
              onChange={sortItems}
            />
            <SearchIcon color="button" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={10}>
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <Typography variant="body1" textAlign="center" sx={{ mr: 2 }}>
              Search by :
            </Typography>
            <ToggleButtonGroup
              color="secondary"
              value={sortValues.searchby}
              exclusive
              onChange={sortItems}
            >
              <ToggleButton
                name="searchby"
                size="small"
                value="projects"
                sx={{ p: 1 }}
              >
                Solar Projects
              </ToggleButton>
              <ToggleButton
                name="searchby"
                size="small"
                value="sp"
                sx={{ p: 1 }}
              >
                Service Provider
              </ToggleButton>
              <ToggleButton
                name="searchby"
                size="small"
                value="location"
                sx={{ p: 1 }}
              >
                Location
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
        <Grid item xs={12} md={2} textAlign="end">
          <Button.VariantButton
            title="Search Projects"
            onClick={handleFilter}
            width="100%"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item xs={12} spacing={2} alignItems="center">
          <Grid item xs={8} md={10}>
            <Typography variant="h6">
              {itemsFiltered.length} Projects Found
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <TextField
              label="Sort by"
              variant="outlined"
              size="small"
              name="sort"
              select
              fullWidth
              value={sortValues.sort || ""}
              onChange={handleSort}
            >
              {sortTypes.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      {(projects.isLoading || isLoading) && (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress color="success" />
        </Box>
      )}
      {projects.errorMessage && (
        <Typography variant="body1" textAlign="center">
          {projects.errorMessage}
        </Typography>
      )}
      {!projects.isLoading && !isLoading && itemsFiltered.length <= 0 && (
        <Typography variant="body1" textAlign="center">
          No projects are available
        </Typography>
      )}
      {!isLoading && (
        <Grid container spacing={2} mb={6}>
          {itemsFiltered.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} mb={2}>
              <ProjectCard item={item} />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
  };
};

export default connect(mapStateToProps, { getProjects })(Projects);

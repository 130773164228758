import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import NewsCard from "../../../components/NewsCard";

const News = () => {
  const news = [{}, {}, {}];

  return (
    <div>
      <Box mb={3} justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          News / Notifications
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {news.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} mb={2}>
            <NewsCard />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default News;

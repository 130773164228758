import React from "react";
import { Grid } from "@mui/material";
import OpeningsCard from "../../../components/OpeningsCard";

const Openings = () => {
  const openings = [
    {
      name: "Software Engineer",
      exp: "0 - 6 years of experience",
      des: "Software engineers focus on applying the principles of engineering to software development. Their role includes analyzing and modifying existing software as well as designing, constructing and testing end-user applications that meet user needs — all through software programming languages",
    },
    {
      name: "UI/UX Designer",
      exp: "0 - 6 years of experience",
      des: "Software engineers focus on applying the principles of engineering to software development. Their role includes analyzing and modifying existing software as well as designing, constructing and testing end-user applications that meet user needs — all through software programming languages",
    },
    {
      name: "Software Engineer",
      exp: "0 - 6 years of experience",
      des: "Software engineers focus on applying the principles of engineering to software development. Their role includes analyzing and modifying existing software as well as designing, constructing and testing end-user applications that meet user needs — all through software programming languages",
    },
    {
      name: "UI/UX Designer",
      exp: "0 - 6 years of experience",
      des: "Software engineers focus on applying the principles of engineering to software development. Their role includes analyzing and modifying existing software as well as designing, constructing and testing end-user applications that meet user needs — all through software programming languages",
    },
    {
      name: "Software Engineer",
      exp: "0 - 6 years of experience",
      des: "Software engineers focus on applying the principles of engineering to software development. Their role includes analyzing and modifying existing software as well as designing, constructing and testing end-user applications that meet user needs — all through software programming languages",
    },
    {
      name: "UI/UX Designer",
      exp: "0 - 6 years of experience",
      des: "Software engineers focus on applying the principles of engineering to software development. Their role includes analyzing and modifying existing software as well as designing, constructing and testing end-user applications that meet user needs — all through software programming languages",
    },
  ];
  return (
    <div>
      <Grid container spacing={4}>
        {openings.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <OpeningsCard item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Openings;

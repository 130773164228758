import React from "react";
import { Box, Grid, Stack, Typography, Divider } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";

const OpeningsCard = ({ item }) => {
  return (
    <Box sx={{ backgroundColor: "#EDEDED" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        px={3}
        py={2}
      >
        <Box>
          <Typography variant="subtitle1" color="#0F6B9E" fontWeight="bold">
            <u>{item.name}</u>
          </Typography>
          <Typography color="#5B5B5B" fontWeight="600">
            {item.exp}
          </Typography>
        </Box>
        <WorkIcon color="button" fontSize="large" />
      </Stack>
      <Divider color="#0F6B9E" sx={{ height: "3px" }} />
      <Box px={3} py={2}>
        <Typography variant="body1" textAlign="justify">
          {item.des}
        </Typography>
      </Box>
    </Box>
  );
};

export default OpeningsCard;

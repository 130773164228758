import React from "react";
import { Container, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "90vh",
      }}
    >
      <Stack m={1} alignItems="center">
        <Typography variant="h1" color="primary">
          404
        </Typography>
        <Typography variant="h4" mt={2} color="textPrimary">
          UH OH.
        </Typography>
        <Typography variant="span" m={2} color="textPrimary">
          There's nothing here
        </Typography>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Go Back
        </Button>
      </Stack>
    </Container>
  );
};

export default NotFound;

import React from "react";
import {
  Grid,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DefaultTextField from "../../../components/DefaultTextField";
import Button from "../../../components/DefaultButton";

const ContactForm = () => {
  return (
    <div>
      <Box mb={4}>
        <Typography my={1} variant="h5" color="textPrimary" align={"center"}>
          Write us a message
        </Typography>
      </Box>
      <Grid px={4} container spacing={4}>
        <Grid item xs={12} sm={6}>
          <DefaultTextField label="Full Name *" name="name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DefaultTextField label="Email Address *" name="employeeid" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DefaultTextField label="Phone Number *" name="phone" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DefaultTextField label="Company Name *" name="companyName" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DefaultTextField
            label="Message *"
            name="message"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Accept Terms & Conditions"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" justifyContent="right">
            <Button.VariantButton
              title="Submit"
              color="button1"
              type="submit"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactForm;

import React from "react";
import { makeStyles } from "@mui/styles";
import {
  IconButton,
  List,
  ListItem,
  Divider,
  Typography,
  Grid,
  Container,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";
import LocationIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#121E31",
  },
  logoContainer: {
    height: 45,
  },
  logoImage: {
    width: "100%",
    height: "100%",
  },
  socialIcon: {
    padding: 10,
    marginRight: "10px",
    color: "#72737C",
    "&:hover": {
      background: "#2B3E80",
      color: "#fff",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

const Footer = ({ ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={5} mt={2} mb={2}>
          <Grid item sm={4}>
            <List disablePadding>
              <ListItem disableGutters>
                <div className={classes.logoContainer}>
                  <a href="/">
                    <img
                      className={classes.logoImage}
                      src="/images/logow.png"
                      alt="logo"
                    />
                  </a>
                </div>
              </ListItem>
              <ListItem disableGutters>
                <Typography variant="caption" color="white">
                  Jolt Energy Ventures Pvt. Ltd. (JOLT) is a first crowdfunding
                  platform that lets General Public invest in renewable energy
                  projects that are vetted by experts, audited for security, and
                  have a huge social impact.
                </Typography>
              </ListItem>
              <ListItem disableGutters>
                <IconButton className={classes.socialIcon}>
                  <FacebookIcon />
                </IconButton>
                <IconButton className={classes.socialIcon}>
                  <InstagramIcon />
                </IconButton>
                <IconButton className={classes.socialIcon}>
                  <TwitterIcon />
                </IconButton>
                <IconButton className={classes.socialIcon}>
                  <YoutubeIcon />
                </IconButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6} sm={2}>
            <List disablePadding>
              <ListItem>
                <Typography variant="subtitle3" color="white">
                  JoltEnergy
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="caption" color="white">
                  Why JoltEnergy
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="caption" color="white">
                  Enterprise
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="caption" color="white">
                  Customer Story
                </Typography>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6} sm={2}>
            <List disablePadding>
              <ListItem>
                <Typography variant="subtitle3" color="white">
                  Company
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="caption" color="white">
                  About Us
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="caption" color="white">
                  Contact Us
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="caption" color="white">
                  Staff Login
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="caption" color="white">
                  FAQ
                </Typography>
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={4}>
            <List disablePadding>
              <ListItem>
                <Typography variant="subtitle3" color="white">
                  Get in Touch with Us
                </Typography>
              </ListItem>
              <ListItem>
                <LocationIcon color="whitecolor" />
                <Typography variant="caption" color="white" ml={1}>
                  Hi-Tech City, Hyderabad, India
                </Typography>
              </ListItem>
              <ListItem>
                <PhoneIcon color="whitecolor" />
                <Typography variant="caption" color="white" ml={1}>
                  +91-98665 96105
                </Typography>
              </ListItem>
              <ListItem>
                <MailIcon color="whitecolor" />
                <Typography variant="caption" color="white" ml={1}>
                  support@JoltEnergy.in
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
      <Divider color="gray" />
      <Grid container justifyContent="center" p={1.5}>
        <Typography variant="caption" color="white">
          JOLT Energy - © 2022 by JOLT Energy Ventures Pvt. Ltd.
        </Typography>
      </Grid>
    </div>
  );
};

export default Footer;

import React from "react";
import { LoadingButton } from "@mui/lab";

const VariantButton = (props) => {
  const { title, width, color, fontsize, padding, ...rest } = props;
  return (
    <LoadingButton
      color={color || "button"}
      variant="contained"
      size="medium"
      style={{
        fontSize: fontsize || "14px",
        fontWeight: "600",
        letterSpacing: "0.5px",
        color: "white",
        borderRadius: "6px",
        padding: padding || "10px",
        textTransform: "none",
        width: width || "200px",
      }}
      {...rest}
    >
      {title}
    </LoadingButton>
  );
};

const OutlinedButton = (props) => {
  const { title, width, color, ...rest } = props;
  return (
    <LoadingButton
      color={color || "button"}
      variant="outlined"
      size="medium"
      style={{
        fontSize: "14px",
        fontWeight: "600",
        letterSpacing: "0.5px",
        color: color || "button",
        borderRadius: "10px",
        padding: "10px",
        textTransform: "none",
        width: width || "200px",
      }}
      {...rest}
    >
      {title}
    </LoadingButton>
  );
};

export default { VariantButton, OutlinedButton };

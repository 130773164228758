import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const Description = () => {
  return (
    <Stack spacing={4}>
      <Box justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          Current Openings
        </Typography>
      </Box>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        The global community for designers and creative
        professionals.Application providers should not allow their products to
        be bundled with applications that do not meet these guidelines. The
        global community for designers and creative professionals.Application
        providers should not allow their products to be bundled with
        applications that do not meet these guidelines. The global community for
        designers and creative professionals.Application providers should not
        allow their products to be bundled with applications that do not meet
        these guidelines.
      </Typography>
    </Stack>
  );
};

export default Description;

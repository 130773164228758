import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const OurPartnership = () => {
  return (
    <div>
      <Box mb={4}>
        <Typography my={1} variant="h5" color="textPrimary" align={"center"}>
          Our Partnership
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="space-around">
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img width="80%" src="images/companylogo.png" />
          </Box>
        </Grid>
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img width="80%" src="images/companylogo.png" />
          </Box>
        </Grid>
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img width="80%" src="images/companylogo.png" />
          </Box>
        </Grid>
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img width="80%" src="images/companylogo.png" />
          </Box>
        </Grid>
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img width="80%" src="images/companylogo.png" />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default OurPartnership;

import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const Description = () => {
  return (
    <Stack spacing={4}>
      <Box justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          Our Partners
        </Typography>
      </Box>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        Solar radiation is absorbed by the Earth's land surface, oceans – which
        cover about 71% of the globe – and atmosphere. Warm air containing
        evaporated water from the oceans rises, causing atmospheric circulation
        or convection. When the air reaches a high altitude, where the
        temperature is low, water vapor condenses into clouds, which rain onto
        the Earth's surface, completing the water cycle. The latent heat of
        water condensation amplifies convection, producing atmospheric phenomena
        such as wind, cyclones and anticyclones.[8] Sunlight absorbed by the
        oceans and land masses keeps the surface at an average temperature of 14
        °C.
      </Typography>
    </Stack>
  );
};

export default Description;

import React from "react";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

const Description = () => {
  return (
    <Stack spacing={4}>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Q) Does this reduce my fixed charges also ?</b>
        <br />
        <b>A)</b> No
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Q) Who will support if there are any faulty solar modules ?</b>
        <br />
        <b>A) </b>JOLT Energy Ventures Support team monitors energy production &
        works with Solar Service Partner on your behalf & fix the issue.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Q) My Unit charge is fixed during the agreement term ?</b>
        <br />
        <b>A) </b>Yes, unlike your current utility company, your unit charge is
        fixed during the agreement term and does not change.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b> Q) Can consumer also able to view or monitor system ?</b>
        <br />
        <b>A) </b>Yes, Using platform app/portal consumers can login and check
        production, consumption, payment history & savings data online.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Q) How to make payment ?</b>
        <br />
        <b>A)</b> During the initial consumer onboarding & system setup process,
        payment processing instructions will be clearly discussed & configured.
        Regular payments without missing will increase consumer ratings & helps
        for further loans funding. Also auto monthly payment from bank accounts
        will give additional discounts.Delay in payments will result in
        penalties for the invoiced amount which was not paid for more than 30
        days.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>
          Q) If the Solar system stopped working for one week, what would happen
          ?
        </b>
        <br />
        <b>A) </b>When the solar system stopped working, our monitoring &
        support team would know that there is some issue. Your business is
        generally not interrupted as power can still be consumed from the grid.
        Incase of any issues with power interruption, consumers can always
        contact the support team & create critical support tickets which will be
        assisted on an immediate basis.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Q) During the Winter & Rainy season what will happen ?</b>
        <br />
        <b>A)</b> Solar modules are designed to produce energy even with limited
        sun/light during winter & rainy season, it may not as much as during
        summer. When a system produces less units than needed during these times
        additional required units can be consumed from the grid.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b> Q) Can I consume more energy than my solar production ?</b>
        <br />
        <b>A)</b> Yes, even your system is designed to generate energy based on
        your average energy consumption, when more energy is needed it can be
        consumed from the grid and when less energy is consumed, remaining
        energy can be to the grid. This will be offset by a 6 months or 12
        months cycle.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Q) During Power loss ?</b>
        <br />
        <b>A) </b>During power loss just like your previous system before solar,
        you will lose power as your system is connected to the grid (electricity
        board) and this is controlled by electricity board (as this can hurt/
        harm anyone who is working to resume power supply. However, your
        existing backup system like inverter/generator/battery will work as
        before.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b> Q) Response Times/SLA ?</b>
        <br />
        <b>A)</b> Response times depend on type & severity of issues.
        <br /> a) For example, power is interrupted due to the solar system, it
        is a critical priority issue which will be looked at & addressed almost
        immediately.
        <br />
        b) For example, one of the panels/modules among many is generating less
        energy than expected which is not a high priority issue and addressed as
        a medium/low priority issue. However, a support team is available to
        answer all your questions.
      </Typography>
    </Stack>
  );
};

export default Description;

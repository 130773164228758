import React from "react";
import { Stack, Typography, Box } from "@mui/material";

const Description = () => {
  return (
    <Stack spacing={4} mb={6}>
      <Box justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          About Consumer
        </Typography>
      </Box>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
      Features section:
      Pay only for what you use
            Contracting with “JOLT” gives you access to an extensive portfolio of energy assets leased from third-party providers. You can lease capacity, purchase energy capacity, or enter into a power purchase agreement.
      Expertise built on experience
          With experience in the energy industry, we know how to save you money. We help our clients identify the right assets for their needs and work with third-parties to provide them. We're not just another broker; we're an experienced advisor that knows how to negotiate rates and terms.
Trust the experts with your electricity bill
Whether you're a small business or multinational company, we've got the expertise and relationships to find the best deals for your electricity needs.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
      Experience the benefits now!
		
    FIXED price/unit Your Power bill will never increase.
    Zero Cost to Consumer 
    No Operational Issues We Warrant, insure, maintain & repair system for you.
    We monitor system for you.
    15-30% Rebate in energy charges starting from Day-1 for 15 years.
    Asset Ownership Free system after 15 years (Warranty is 25 years).
    Refundable Deposit (~10-20% deposit paid during agreement is refundable after term) 
    Technology based platform
      </Typography>
    </Stack>
  );
};

export default Description;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Drawer } from "@mui/material";
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
} from "@mui/material";
import {
  serviceProviderUrl,
  investorUrl,
  consumerUrl,
} from "../../../constants";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "100%",
    maxWidth: 350,
  },
}));

const Sidebar = ({ open, handleSidebar }) => {
  const classes = useStyles();

  const items = [
    {
      title: "I am Investor",
      link: "/investor",
    },
    {
      title: "I am Consumer",
      link: "/consumer",
    },
    {
      title: "I am Service-Provider",
      link: "/serviceprovider",
    },
    {
      isDivider: true,
    },
    {
      title: "About Company",
      link: "/aboutus",
    },
    {
      title: "Contact Us",
      link: "/contactus",
    },
    {
      title: "Our Mission, Vision & Values",
      link: "/mission-vision",
    },
    {
      title: "Our Policy",
      link: "/privacypolicy",
    },
    {
      title: "Our Partners",
      link: "/ourpartners",
    },
    {
      title: "Careers",
      link: "/careers",
    },
    {
      title: "FAQ",
      link: "/faq",
    },
    {
      title: "Blog",
      link: "/blog",
    },
    {
      isDivider: true,
    },
    {
      title: "Investor Login",
      link: investorUrl,
    },
    {
      title: "Consumer Login",
      link: consumerUrl,
    },
    {
      title: "Service Provider Login",
      link: serviceProviderUrl,
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={handleSidebar}
      open={open}
      variant="temporary"
    >
      <List sx={{ mt: 10 }}>
        {items.map((item, index) =>
          item.isDivider ? (
            <Divider key={index} sx={{ background: "#dadce0" }} />
          ) : (
            <ListItem key={index} disablePadding>
              <ListItemButton component="a" href={item.link}>
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{
                    color: "#32405A",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;

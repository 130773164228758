import React, { useState } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";

const Header = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div>
      <Topbar handleSidebar={handleSidebar} />
      <Sidebar open={open} handleSidebar={handleSidebar} />
    </div>
  );
};

export default Header;

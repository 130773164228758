import palette from "./palette";

const typography = {
  fontFamily: "Open Sans",
  h1: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: 1.1,
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "38px",
    lineHeight: 1.5,
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: 1.25,
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: 1.35,
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: 1.4,
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: 1.45,
  },
  subtitle1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: 1.4,
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: 1.4,
  },
  body1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.3,
  },
  body2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.3,
  },
  button: {
    color: palette.text.primary,
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: 1,
  },
  caption: {
    color: palette.text.secondary,
    fontSize: "12px",
    lineHeight: 1.35,
  },
  caption2: {
    color: palette.text.secondary,
    fontSize: "12px",
    lineHeight: 1.35,
    fontWeight: "400",
  },
  caption3: {
    color: palette.text.secondary,
    fontSize: "10px",
  },
  caption4: {
    color: palette.text.secondary,
    fontSize: "8px",
  },
  caption5: {
    color: palette.text.primary,
    fontSize: "10px",
  },
  overline: {
    fontSize: "13px",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
};

export default typography;

import React from "react";
import { TextField, Typography, Box } from "@mui/material";
import { styled } from "@mui/styles";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#E8E8E8",
    // padding: "2px 0px",
  },
});

const DefaultTextField = (props) => {
  const { label, type, ...rest } = props;
  return (
    <div>
      <Typography variant="caption2" fontWeight="600" color="#121E31">
        {label}
      </Typography>
      <Box m={0.5} />
      <CssTextField
        type={type || "text"}
        variant="outlined"
        size="small"
        fullWidth
        {...rest}
      />
    </div>
  );
};

export default DefaultTextField;

import React from "react";
import { makeStyles, styled } from "@mui/styles";
import { Stack, Container, Box, Typography } from "@mui/material";
import { Header, Footer } from "../../components/Layouts";
import Hero from "../../components/Hero";
import Blogs from "./components/Blogs";
import News from "../homepage/components/News";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: "80px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    background: "linear-gradient(180deg,  #39C7FF 900px, #fff 900px)",
    height: "100%",
  },
  container: {
    borderTopLeftRadius: "150% 90%",
    position: "absolute",
    backgroundColor: "#61CBF5",
    top: "110px",
    height: "900px",
    width: "100%",
    zIndex: 1,
  },
}));

const BlogPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Header />
      <div className={classes.section}>
        <div className={classes.container}></div>
        <DefContainer maxWidth="lg" sx={{ margin: "80px auto", zIndex: 2 }}>
          <Stack mx={{ xs: 2, md: 0 }} spacing={8}>
            <Hero
              title="Our Blog"
              subtitle="CrowdEnergy is a unique tech platform that lets people fund decisions in the energy sector. Our goal is to make it easier for people to participate in decisions about their energy future, and to create a better future for our planet."
              image="home/image.png"
            />
            <Box
              p={{ xs: 3, md: 6 }}
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #539B44",
                borderRadius: 4,
                zIndex: 2,
              }}
            >
              <Blogs />
            </Box>
            <News />
          </Stack>
        </DefContainer>
      </div>
      <Footer />
    </div>
  );
};

const DefContainer = styled(Container)(({ theme }) => ({
  padding: 0,
}));

export default BlogPage;

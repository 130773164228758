import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import NewsCard from "../../../components/NewsCard";

const Companies = () => {
  const companies = [
    "images/company.png",
    "images/company.png",
    "images/company.png",
    "images/company.png",
    "images/company.png",
    "images/company.png",
  ];

  return (
    <div>
      <Grid container spacing={2}>
        {companies.map((item, index) => (
          <Grid item xs={4} sm={3} md={2} key={index} mb={2}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img width="80%" src={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Companies;

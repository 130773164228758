import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import BlogCard from "../../../components/BlogCard";

const Blogs = () => {
  const blogs = [
    {
      name: "Widespread group",
      description:
        "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      category: "Food, Travel",
      date: "Nov 13, 2021",
    },
    {
      name: "Widespread group",
      description:
        "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      category: "Food, Travel",
      date: "Nov 13, 2021",
    },
    {
      name: "Widespread group",
      description:
        "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      category: "Food, Travel",
      date: "Nov 13, 2021",
    },
    {
      name: "Widespread group",
      description:
        "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      category: "Food, Travel",
      date: "Nov 13, 2021",
    },
    {
      name: "Widespread group",
      description:
        "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      category: "Food, Travel",
      date: "Nov 13, 2021",
    },
    {
      name: "Widespread group",
      description:
        "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      category: "Food, Travel",
      date: "Nov 13, 2021",
    },
    {
      name: "Widespread group",
      description:
        "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      category: "Food, Travel",
      date: "Nov 13, 2021",
    },
  ];

  return (
    <div>
      <Box mb={3} justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          Our Blog
        </Typography>
      </Box>
      <Grid container spacing={6}>
        {blogs.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} mb={2}>
            <BlogCard item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Blogs;

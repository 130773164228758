import React from "react";
import { Typography, Card, CardContent, Stack, Box } from "@mui/material";

const NewsCard = () => {
  return (
    <Card>
      <Stack p={2} direction="row" alignItems="center" spacing={2}>
        <img
          src={"/images/cardImage2.png"}
          width="60"
          height="60"
          alt="profile"
        />
        <Box>
          <Typography
            color="textPrimary"
            mb={1}
            variant="body2"
            component="div"
            fontWeight="bold"
          >
            An Illustrated Diagram
          </Typography>
          <Typography variant="caption" color="textPrimary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

export default NewsCard;

import React from "react";
import { makeStyles, styled } from "@mui/styles";
import {
  Box,
  Stack,
  Toolbar,
  Hidden,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  tooltipClasses,
  Button,
  AppBar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  serviceProviderUrl,
  investorUrl,
  consumerUrl,
} from "../../../constants";

const useStyles = makeStyles((theme) => ({
  logoImage: {
    width: "200px",
    height: "100%",
  },
  menuItem: {
    minHeight: "inherit",
    textAlign: "center",
    fontWeight: "600",
    color: "#32405A",
    padding: "16px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& > svg": {
      transition: "transform 0.3s ease",
    },
    "&:hover": {
      color: "#000",
      borderBottom: "2px solid #32405A",
    },
    "&:hover > svg": {
      transform: "rotateZ(-180deg)",
    },
  },
  menuLink: {
    paddingTop: 12,
    paddingBottom: 12,
    color: "#32405A",
    fontSize: 14,
    fontWeight: 600,
  },
}));

const Topbar = ({ handleSidebar }) => {
  const classes = useStyles();
  const [anchor, setAnchor] = React.useState(null);

  const handleMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <AppBar color="inherit">
      <Stack direction="row" pt={1} px={4} spacing={3}>
        <Typography variant="caption" color="black" fontWeight="bold">
          Welcome to CFE, Jolt Energy
        </Typography>
        <Box sx={{ flexGrow: "1" }} />
        <Hidden mdDown>
          <Button
            size="small"
            color="primary"
            startIcon={<LocalPhoneRoundedIcon />}
          >
            +91-98665 96105
          </Button>
          <Button
            size="small"
            color="primary"
            startIcon={<EmailRoundedIcon />}
            sx={{ textTransform: "none" }}
          >
            support@JoltEnergy.in
          </Button>
        </Hidden>
      </Stack>
      <Toolbar sx={{ alignItems: "center" }}>
        <a href="/" title="crowdfund">
          <img
            className={classes.logoImage}
            src={"/images/logov.png"}
            alt="logo"
          />
        </a>
        <Box sx={{ flexGrow: "1" }} />
        <Hidden mdDown>
          <MenuToolbar
            title={
              <div style={{ paddingBottom: "8px" }}>
                <MenuItem
                  component="a"
                  href="/investor"
                  className={classes.menuLink}
                >
                  I am Investor
                </MenuItem>
                <MenuItem
                  component="a"
                  href="/consumer"
                  className={classes.menuLink}
                >
                  I am Consumer
                </MenuItem>
                <MenuItem
                  component="a"
                  href="/serviceprovider"
                  className={classes.menuLink}
                >
                  I am Service-Provider
                </MenuItem>
              </div>
            }
          >
            <Box className={classes.menuItem}>
              How It Works
              <ArrowDropDownIcon />
            </Box>
          </MenuToolbar>
          <MenuItem
            component="a"
            href="/solarprojects#projects"
            className={classes.menuItem}
          >
            Solar Projects
          </MenuItem>
          <MenuToolbar
            title={
              <div style={{ paddingBottom: "8px" }}>
                <MenuItem
                  component="a"
                  href="/aboutus"
                  className={classes.menuLink}
                >
                  About Company
                </MenuItem>
                <MenuItem
                  component="a"
                  href="/contactus"
                  className={classes.menuLink}
                >
                  Contact Us
                </MenuItem>
                <MenuItem
                  component="a"
                  href="/mission-vision"
                  className={classes.menuLink}
                >
                  Our Mission, Vision & Values
                </MenuItem>
                <MenuItem
                  component="a"
                  href="/privacypolicy"
                  className={classes.menuLink}
                >
                  Our Policy
                </MenuItem>
                <MenuItem
                  component="a"
                  href="/ourpartners"
                  className={classes.menuLink}
                >
                  Our Partners
                </MenuItem>
                <MenuItem
                  component="a"
                  href="/careers"
                  className={classes.menuLink}
                >
                  Careers
                </MenuItem>
                <MenuItem
                  component="a"
                  href="/faq"
                  className={classes.menuLink}
                >
                  FAQ
                </MenuItem>
              </div>
            }
          >
            <Box className={classes.menuItem}>
              Company
              <ArrowDropDownIcon />
            </Box>
          </MenuToolbar>
          <MenuItem component="a" href="/blog" className={classes.menuItem}>
            Blog
          </MenuItem>
          <Box sx={{ flexGrow: "10" }} />
          <Button
            aria-controls={Boolean(anchor) ? "menu" : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchor) ? "true" : undefined}
            variant="outlined"
            startIcon={<PersonRoundedIcon />}
            endIcon={<ArrowDropDownIcon />}
            sx={{ textTransform: "none", py: 1 }}
            onClick={handleMenu}
          >
            Login
          </Button>
          <Menu
            id="menu"
            anchorEl={anchor}
            open={Boolean(anchor)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handleClose}
          >
            <MenuItem
              component="a"
              href={investorUrl}
              className={classes.menuLink}
            >
              Investor Login
            </MenuItem>
            <MenuItem
              component="a"
              href={consumerUrl}
              className={classes.menuLink}
            >
              Consumer Login
            </MenuItem>
            <MenuItem
              component="a"
              href={serviceProviderUrl}
              className={classes.menuLink}
            >
              Service Provider Login
            </MenuItem>
          </Menu>
        </Hidden>
        <Hidden mdUp>
          <IconButton onClick={handleSidebar} aria-label="Menu">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

const MenuToolbar = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    margin: "0px",
    borderRadius: "0px",
    marginTop: "0px !important",
    padding: "0px",
  },
});

export default Topbar;

import React from "react";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  CardActionArea,
  Button,
  Stack,
  Box,
} from "@mui/material";

const BlogCard = ({ item }) => {
  return (
    <Card>
      <CardMedia
        component="img"
        height="220"
        image="/images/rect-bg.svg"
        alt="green iguana"
      />
      <CardContent>
        <Typography variant="caption" color="text.primary" fontWeight="600">
          {item.category} - {item.date}
        </Typography>
        <Typography variant="h6" component="div" gutterBottom>
          {item.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="secondary" sx={{ px: 2, py: 1 }}>
          Read More
        </Button>
      </CardActions>
    </Card>
  );
};

export default BlogCard;

import React from "react";
import { Stack, Typography, Box } from "@mui/material";

const Description = () => {
  return (
    <Stack spacing={4} mb={6}>
      <Box justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          How It works ? 
        </Typography>
      </Box>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
      The bottom line
We make it easy for you to contribute towards renewable energy projects, without needing to invest large amounts of money.
It takes only 2 minutes!
All you need to do is choose your project and click "contribute".
Investments are always backed by the project you back, meaning that if they can't meet their goal, your contribution will be refunded.
Start small and go big!
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
      Diversity of Projects
We have projects ranging from 25kW to 500kW, in various industry verticals to pick & invest in your budget. Choose the one that's best for you and start funding!
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
      Features section:
Decentralized
JoltEnery is a decentralized platform that connects people with great ideas to those looking to invest in sustainable solutions.
Sharing economy
We're not just investing your funds into clean energy projects; we're also creating a sharing economy for those who want to be part of the solution.
Community
We're all about transparency and giving back to the community. That's why we let investors know where their money is going and how it's being used.
      </Typography>
    </Stack>
  );
};

export default Description;

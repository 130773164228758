import React from "react";
import { Grid, Typography, Box, Stack } from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

const ContactDetails = () => {
  return (
    <div>
      <Box mb={5} justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          Contact Us
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Stack spacing={2} direction="row">
            <LocalPhoneOutlinedIcon color="button" fontSize="large" />
            <Box>
              <Typography color="#32405A" fontWeight="700">
                Phone Number
              </Typography>
              <Typography color="#32405A" fontWeight="700">
                +91 98665 96105
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack spacing={2} direction="row">
            <EmailOutlinedIcon color="button" fontSize="large" />
            <Box>
              <Typography color="#32405A" fontWeight="700">
                Email
              </Typography>
              <Typography color="#32405A" fontWeight="700">
                Support@JoltEnergy.in
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack spacing={2} direction="row">
            <FmdGoodOutlinedIcon color="button" fontSize="large" />
            <Box>
              <Typography color="#32405A" fontWeight="700">
                Our Address
              </Typography>
              <Typography color="#32405A" fontWeight="700">
                Cyber Towers, Hi-Tech City, Hyderabad, Telangana, India
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactDetails;

import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";

const Hero = ({ title, subtitle, image, getStarted, href }) => {
  return (
    <Grid
      container
      py={{ xs: 1, md: 4 }}
      px={{ xs: 3, md: 6 }}
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <Typography variant="h2" color="#fff" fontWeight="bold" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" color="#fff" textAlign="justify" gutterBottom>
          {subtitle}
        </Typography>
        {getStarted && (
          <Box mt={5}>
            <Button
              variant="contained"
              size="medium"
              component="a"
              href={href}
              style={{
                fontSize: "16px",
                fontWeight: "600",
                letterSpacing: "0.5px",
                color: "#28C2FF",
                backgroundColor: "#fff",
                borderRadius: "6px",
                padding: "16px",
                textTransform: "none",
                width: "200px",
              }}
            >
              Get Started
            </Button>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <img src={image} width="100%" />
      </Grid>
    </Grid>
  );
};

export default Hero;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";

const useStyles = makeStyles((theme) => ({
  socialIcon: {
    padding: 10,
    color: "#72737C",
    "&:hover": {
      background: "#2B3E80",
      color: "#fff",
    },
  },
}));

const ConnectDetails = () => {
  const classes = useStyles();
  return (
    <div>
      <Box mb={4}>
        <Typography my={1} variant="h5" color="textPrimary" align={"center"}>
          Connect
        </Typography>
      </Box>
      <Stack direction="row" spacing={2} justifyContent="center">
        <IconButton className={classes.socialIcon}>
          <FacebookIcon />
        </IconButton>
        <IconButton className={classes.socialIcon}>
          <InstagramIcon />
        </IconButton>
        <IconButton className={classes.socialIcon}>
          <TwitterIcon />
        </IconButton>
        <IconButton className={classes.socialIcon}>
          <YoutubeIcon />
        </IconButton>
      </Stack>
    </div>
  );
};

export default ConnectDetails;

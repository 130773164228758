export const sortTypes = ["NEWEST", "OLDER"];
export const categoryTypes = ["ALL", "COMPLETED", "PENDING"];

export const serviceProviderUrl = "https://sp.crowdfundenergy.in";
export const investorUrl = "https://investor.crowdfundenergy.in";
export const consumerUrl = "https://consumer.crowdfundenergy.in";
export const staffUrl = "https://staff.crowdfundenergy.in";

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

import React from "react";
import { Stack, Typography } from "@mui/material";

const Description = () => {
  return (
    <Stack spacing={4}>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Reporting security issues</b>
        <br />
        If you believe you have discovered a vulnerability in a product or have
        a security incident to report, go to goo.gl/vulnz to include it in our
        Vulnerability Reward Program. Upon receipt of your message we will send
        an automated reply that includes a tracking identifier. If you feel the
        need, please use our PGP public key to encrypt your communications with
        us.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Vulnerability disclosure policy</b>
        <br />
        We believe that vulnerability disclosure is a two-way street. Vendors,
        as well as researchers, must act responsibly. This is why adheres to a
        90-day disclosure deadline. We notify vendors of vulnerabilities
        immediately, with details shared in public with the defensive community
        after 90 days, or sooner if the vendor releases a fix. That deadline can
        vary in the following ways:
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Installation</b>
        <br />
        We believe that software should not trick you into installing it. It
        should be clear to you when you are installing or enabling software on
        your computer and you should have the ability to say no. An application
        shouldn’t install itself onto your computer secretly or by hiding within
        another program that you’re installing or updating. You should be
        conspicuously notified of the functions of all the applications in a
        bundle.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Upfront disclosure</b>
        <br />
        When an application is installed or enabled, it should inform you of its
        principal and significant functions. And if the application makes money
        by showing you advertising, it should clearly and conspicuously explain
        this. This information should be presented in a way that a typical user
        will see and understand – not buried in small print that requires you to
        scroll. For example, if the application is paid for by serving pop-up
        ads or sending your personal data to a third party, that should be made
        clear to you.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Simple removal</b>
        <br />
        It should be easy for you to figure out how to disable or delete an
        application. The process should try to remove sufficient components to
        disable all functions of the application, visible or not, without
        messing up your computer. Once an application is disabled or deleted, it
        should not remain active or be enabled automatically later by itself or
        another application.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Clear behaviour</b>
        <br />
        Applications that affect or change your user experience should make it
        clear that they are the reason for those changes. For example, if an
        application opens a window, that window should identify the application
        responsible for it. Applications should not intentionally obscure
        themselves under multiple or confusing names. You should be given the
        means to control the application in a straightforward manner, such as by
        clicking visible elements generated by the application. If an
        application shows you ads, it should clearly mark them as advertising
        and inform you that they originate from that application. If an
        application makes a change designed to affect the user experience of
        other applications (such as setting your homepage), then those changes
        should be made clear to you.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Snooping</b>
        <br />
        If an application collects or transmits your personal information such
        as your address, you should know. We believe you should be asked
        explicitly for your permission in a manner that is obvious and clearly
        states what information will be collected or transmitted. For more
        detail, it should be easy to find a privacy policy that discloses how
        the information will be used and whether it will be shared with third
        parties.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        <b>Keeping good company</b>
        <br />
        Application providers should not allow their products to be bundled with
        applications that do not meet these guidelines.
      </Typography>
    </Stack>
  );
};

export default Description;

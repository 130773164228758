import React from "react";
import { Stack, Typography, Box } from "@mui/material";

const Description = () => {
  return (
    <Stack spacing={4} mb={6}>
      <Box justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          About Service Provider
        </Typography>
      </Box>
      <Typography variant="subtitle1" color="textPrimary" align="justify">Every Service Provider on our platform is carefully selected & certified based on their work experience, professional work ethic, and long term relation. 

Our service provider not only does the job right, but is available for service when this is not right as per SLAs. 

      </Typography>
    </Stack>
  );
};

export default Description;

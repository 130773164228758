import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography, Button, Link } from "@mui/material";
import {
  investorUrl,
  serviceProviderUrl,
  consumerUrl,
} from "../../../constants";

const useStyles = makeStyles((theme) => ({
  liftUp: {
    border: "1px solid #539B44",
    borderRadius: "25px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
    transition:
      "box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease",
    "&:hover": {
      boxShadow: "0 0rem 2rem rgba(0,0,0,.20) !important",
      transform: "translate3d(0,-5px,0)",
    },
  },
}));

const Products = () => {
  const classes = useStyles();

  return (
    <div>
      <Box mb={3} justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          Users
        </Typography>
      </Box>
      <Grid container spacing={8} mb={10}>
        <Grid item xs={12} md={4} textAlign="center">
          <Link href={investorUrl} underline="none">
            <Box px={3} pt={3} pb={6} className={classes.liftUp}>
              <img
                src="home/investor.png"
                width="70%"
                style={{ marginBottom: "20px" }}
              />
              <Typography
                variant="h5"
                color="textPrimary"
                textAlign="center"
                gutterBottom
              >
                Investor
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                textAlign="justify"
                mb={4}
              >
                BackedBacked by Google and loved by app development teams - from
                startups to global enterprises.
              </Typography>
              <Button
                component="a"
                href="/investor"
                variant="outlined"
                sx={{ textTransform: "none", px: 3, py: 1.2 }}
              >
                Learn More
              </Button>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} textAlign="center">
          <Link href={consumerUrl} underline="none">
            <Box px={3} pt={3} pb={6} className={classes.liftUp}>
              <img
                src="home/consumer.png"
                width="70%"
                style={{ marginBottom: "20px" }}
              />
              <Typography
                variant="h5"
                color="textPrimary"
                textAlign="center"
                gutterBottom
              >
                Consumer
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                textAlign="justify"
                mb={4}
              >
                BackedBacked by Google and loved by app development teams - from
                startups to global enterprises.
              </Typography>
              <Button
                component="a"
                href="/consumer"
                variant="outlined"
                sx={{ textTransform: "none", px: 3, py: 1.2 }}
              >
                Learn More
              </Button>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} textAlign="center">
          <Link href={serviceProviderUrl} underline="none">
            <Box px={3} pt={3} pb={6} className={classes.liftUp}>
              <img
                src="home/sp.png"
                width="70%"
                style={{ marginBottom: "20px" }}
              />
              <Typography
                variant="h5"
                color="textPrimary"
                textAlign="center"
                gutterBottom
              >
                Service provider
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                textAlign="justify"
                mb={4}
              >
                BackedBacked by Google and loved by app development teams - from
                startups to global enterprises.
              </Typography>
              <Button
                component="a"
                href="/serviceprovider"
                variant="outlined"
                sx={{ textTransform: "none", px: 3, py: 1.2 }}
              >
                Learn More
              </Button>
            </Box>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default Products;

import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./views/homepage";
import InvestorPage from "./views/investor";
import ConsumerPage from "./views/consumer";
import ServiceProviderPage from "./views/serviceprovider";
import ProjectsPage from "./views/projects";
import AboutPage from "./views/aboutpage";
import PartnersPage from "./views/partnerspage";
import MissionVisionPage from "./views/mission-visionpage";
import PrivacyPage from "./views/privacypage";
import FAQPage from "./views/faqpage";
import CareersPage from "./views/careerspage";
import ContactPage from "./views/contactpage";
import BlogPage from "./views/blogpage";
import NotFound from "./views/sessions/NotFound";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/investor" element={<InvestorPage />} />
      <Route path="/consumer" element={<ConsumerPage />} />
      <Route path="/serviceprovider" element={<ServiceProviderPage />} />
      <Route path="/solarprojects" element={<ProjectsPage />} />
      <Route path="/aboutus" element={<AboutPage />} />
      <Route path="/ourpartners" element={<PartnersPage />} />
      <Route path="/mission-vision" element={<MissionVisionPage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/privacypolicy" element={<PrivacyPage />} />
      <Route path="/careers" element={<CareersPage />} />
      <Route path="/contactus" element={<ContactPage />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AllRoutes;

import React, { useState } from "react";
import { ThemeProvider, CssBaseline, Paper } from "@mui/material";
import theme from "./styles/Theme";
import { BrowserRouter } from "react-router-dom";
import AllRoutes from "./routes";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Paper elevation={0}>
        <BrowserRouter>
          <AllRoutes />
        </BrowserRouter>
      </Paper>
    </ThemeProvider>
  );
};

export default App;

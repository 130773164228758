import React from "react";
import { styled } from "@mui/styles";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Avatar,
  Rating,
  Stack,
  Box,
  IconButton,
} from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import WorkIcon from "@mui/icons-material/Work";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Button from "../DefaultButton";
import { investorUrl } from "../../constants";

const ProjectCard = ({ item }) => {
  return (
    <Card>
      <div style={{ position: "relative" }}>
        <CardMedia
          component="img"
          height="200"
          image="/images/rect-bg.svg"
          alt="profile"
        />
        <Box
          p={1}
          style={{
            position: "absolute",
            backgroundColor: "#fff",
            bottom: 20,
            left: 0,
          }}
        >
          Project Status
        </Box>
      </div>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
        >
          <Stack>
            {/* <Typography color="primary" mb={1} variant="body3" component="div">
              Solar Project
            </Typography> */}
            <Typography variant="body4" color="textPrimary" component="a">
              {item.project_title}
            </Typography>
          </Stack>
          {/* <IconButton>
            {item.isfavourite ? (
              <FavoriteIcon color="error" />
            ) : (
              <FavoriteBorderIcon />
            )}
          </IconButton> */}
        </Stack>
        <Typography variant="caption">
          Terms of service are the legal agreements between a service provider
          and a person who wants to use that service.
        </Typography>
        <Typography
          variant="body4"
          color="textPrimary"
          component="div"
          textAlign="end"
        >
          80/100
        </Typography>
        <BorderLinearProgress variant="determinate" value={80} />
        <Stack direction="row" spacing={2} my={1} alignItems="center">
          <PaymentsIcon color="greyicon" fontSize="small" />
          <Typography
            variant="caption2"
            color="textPrimary"
            fontWeight="bold"
            sx={{ flexGrow: "1" }}
          >
            Per Share Cost :
          </Typography>
          <Typography variant="caption2" color="textPrimary" fontWeight="bold">
            5,345 INR
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} my={1} alignItems="center">
          <PaymentsIcon color="greyicon" fontSize="small" />
          <Typography
            variant="caption2"
            color="textPrimary"
            sx={{ flexGrow: "1" }}
          >
            Excepted return * :
          </Typography>
          <Typography variant="caption2" color="textPrimary">
            11,000/year (for 15yrs)*
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} my={1} alignItems="center">
          <PaymentsIcon color="greyicon" fontSize="small" />
          <Typography
            variant="caption2"
            color="textPrimary"
            sx={{ flexGrow: "1" }}
          >
            Project Cost :
          </Typography>
          <Typography variant="caption2" color="textPrimary">
            10,00,000 INR
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} my={1} alignItems="center">
          <WorkIcon color="greyicon" fontSize="small" />
          <Typography
            variant="caption2"
            color="textPrimary"
            sx={{ flexGrow: "1" }}
          >
            End Date : 31 Apr 2022
          </Typography>
          <Typography variant="caption2" color="textPrimary">
            45 Days left
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} mt={3} alignItems="center">
          <Avatar sx={{ width: 36, height: 36 }} />
          <Stack sx={{ flexGrow: 1 }}>
            <Typography variant="body2" fontWeight="bold" color="textPrimary">
              Rirvana Energy
            </Typography>
            <Typography variant="caption2" color="textPrimary">
              12 Campaign
            </Typography>
          </Stack>
          <Rating name="read-only" size="small" value={3} max={3} readOnly />
        </Stack>
        <Stack mt={2} direction="row" spacing={2} alignItems="center">
          <Button.VariantButton
            component="a"
            href={investorUrl}
            title="Invest"
            width="100%"
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#eeeeee",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#F2994A",
  },
}));

export default ProjectCard;

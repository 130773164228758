import React from "react";
import { Stack, Typography, Box } from "@mui/material";

const Description = () => {
  return (
    <Stack spacing={4}>
      <Box justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          Our Mission
        </Typography>
      </Box>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        Work In Progress
      </Typography>
      <Box justifyContent="center">
        <Typography variant="h4" color="textPrimary" align={"center"}>
          Vision & Values
        </Typography>
      </Box>
      <Typography variant="subtitle1" color="textPrimary" align="justify">
        Work in Progress
      </Typography>
    </Stack>
  );
};

export default Description;
